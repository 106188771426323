import { Stack, Divider, Typography, Box } from "@mui/material"
import { spacing } from "@mui/system"
import React from "react"
import styled from "styled-components"

const NotFound = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                alignItems="center"
            >
                <Typography variant="h3" sx={{ color: "primary.main" }}>
                    404
                </Typography>
                <Typography variant="h4">страница не найдена</Typography>
            </Stack>
        </Box>
    )
}

export default NotFound
